<template>
  <div class="refund-page">
    <template v-if="userinfo && userinfo.openid">
      <div class="record-info" v-if="!result">
        <p class="record-header">请输入您的QQ小程序绑定码：</p>
          <div class="record-item">
            <input type="text" v-model="code">
            <van-button 
              round  
              type="danger" 
              block
              :disable="!code"
              @click="bind"
            >绑定</van-button>
          </div>
      </div>
      <div v-else>
        <p class="success-tip">绑定成功！<br>可以在QQ小程序进行提现了~</p>
      </div>
    </template>
  </div>
</template>

<script>
import { Empty } from 'vant'
import { bindWxOpenid } from '../api/user'

export default {
  components: {
    [Empty.name]: Empty,
  },
  data () {
    return {
      code: '',
      result: false,
    }
  },
 
  methods: {
    bind () { 
      bindWxOpenid({
        wxCode: this.code,
        wxOpenid: this.userinfo.openid
      })
        .then(res => { 
          if (res.code === 0) {
            this.result = true
            this.$toast.success('绑定成功')
          } else { 
            this.$toast.fail('出现未知错误')
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';

.refund-page {
  padding-top: 200px;
  .success-tip {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
  }
  .record-info {
    margin: -5px 0 15px;
    padding: 15px 30px;
    border-radius: 4px;
    .record-header {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .record-item {
      input {
        background-color: #fff;
        height: 44px;
        padding: 8px 15px;
        border: 1px solid #ddd;
        outline: none;
        border-radius: 22px;
        background-color: #f5f5f5;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
</style>